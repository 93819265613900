/*------------------------------------*\
  #Form
\*------------------------------------*/
$blue-primary: #293658;
$blue-secondary: #3881b6;
$red-primaray: #e42423;
$gray-primary: #f0f0f0;

.c-form {
  display: flex;
  flex-direction: column;
  &__row {
    margin-bottom: 1.5rem;

    @media screen and (max-width: 500px) {
      margin-bottom: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &--half {
      display: flex;

      @media screen and (max-width: 500px) {
        flex-direction: column;
      }

      .c-form__group {
        width: 50%;

        @media screen and (max-width: 500px) {
          width: 100%;
        }

        &:first-of-type {
          margin-right: 1.5rem;

          @media screen and (max-width: 500px) {
            margin-right: 0;
          }
        }
      }
      .c-subscription__select {
        width: 100% !important;

        select {
          width: 100%;
        }
      }
    }
    &--name {
      display: flex;
      @media screen and (max-width: 500px) {
        flex-direction: column;
      }
      .c-form__group:nth-of-type(1), .c-form__group:nth-of-type(3) {
        width: 40%;
        @media screen and (max-width: 500px) {
          width: 100%;
        }
      }
      .c-form__group:nth-of-type(2) {
        width: 20%;
        margin: 0 1.5rem;
        @media screen and (max-width: 500px) {
          width: 100%;
          margin: 0 0 1.5rem;
        }
      }
    }
    &--location {
      display: flex;

      @media screen and (max-width: 500px) {
        flex-direction: column;
      }
      .c-form__group {
        width: 100%;
      }
      .c-form__group:nth-of-type(2) {
        margin: 0 1.5rem;

        @media screen and (max-width: 500px) {
          margin: 0 0 1.5rem;
        }
      }
    }
  }
  &__group {
    @media screen and (max-width: 500px) {
      margin-bottom: 1.5rem;
    }

    label {
      font-size: 1.6rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      margin-bottom: .25rem;
      color: $blue-primary;

      button {
        margin-left: .5rem;
      }
    }

    input[type=text], input[type=email], select {
      font-family: 'Roboto', serif;
      padding: .6rem 1rem;
      display: flex;
      font-size: 1.8rem;
      border: 1px solid $blue-primary;
      -webkit-appearance: none;
      border-radius: .5rem;
      width: 100%;
      color: $blue-primary;
    }

    input[type=checkbox] {
      height: auto;
      width: auto;
      appearance: checkbox;
      -webkit-appearance: checkbox;
      -ms-progress-appearance: checkbox;
      -moz-appearance: checkbox;
      cursor: pointer;
    }

    textarea {
      width: 100%;
      resize: vertical;
    }

    select {
      cursor: pointer;
    }

    &--checkbox {
    }

    &--button {
    }
  }
  &__select {
  }
}
