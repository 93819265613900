.c-faq {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__half {
    width: calc(50% - 2.3rem);

    @media screen and (max-width: 650px) {
      width: 100%;
    }
  }

  &__single {
    width: 100%;
    margin-bottom: 3rem;
    dt {
      button {
        background-color: transparent;
        border: none;
        border-bottom: 2px solid $clr-primary;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: .5rem 0 .75rem;

        span {
          font-family: 'Merlod-SemiBold', serif;
          font-size: 2.4rem;
          text-align: left;
          line-height: 1.2;
          transition: $transition2;
          margin-right: 2rem;
        }
        figure {
          @include square-dimensions(3rem);
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          span {
            position: absolute;
            width: 1.7rem;
            height: .5rem;
            background-color: $clr-primary;
            display: flex;
            margin: 0 !important;

            &:nth-of-type(1) {
              transform: rotate(90deg);
            }
          }
        }
      }
    }
    dd {
      margin: 0;
      padding: 0;
      opacity: 0;
      height: 0;
      pointer-events: none;
      transition: $transition2;
      p {
        font-family: 'Merlod-Light', serif;
        font-size: 2rem;
        line-height: 1.4;
        a {

        }
      }
    }
  }
}

.js-faqIsActive {

  dt {
    figure {
      span:nth-of-type(1) {
        opacity: 0;
      }
    }
  }

  dd {
    opacity: 1;
    height: initial;
    pointer-events: all;
    padding: 2rem 0 0;
  }
}
