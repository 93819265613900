$blue-primary: #293658;
$blue-secondary: #3881b6;
$red-primary: #e42423;
$gray-primary: #f0f0f0;

body {
  font-family: 'Roboto', serif;
  background-color: $gray-primary;
}
.c-intro {
  margin: 3rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin-bottom: 2rem;

    @media screen and (max-width: 550px) {
      max-width: calc(100% - 6rem);
    }
  }

  h1 {
    font-size: 3rem;
  }
}
.c-subscription {
  width: 100%;
  max-width: 110rem;
  margin: 0 auto;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  overflow: hidden;
  position: relative;
  padding-bottom: 10rem;

  @media screen and (max-width: 1023px) {
    max-width: calc(100% - 7rem);
  }
  @media screen and (max-width: 550px) {
    max-width: 100%;
    border-radius: 0 !important;
  }

  &--has-footer {

    .c-subscription__body {
      padding-bottom: 8rem;
    }
  }

  &__header {
    display: flex;
    position: relative;
    z-index: 2;

    @media screen and (max-width: 900px) {
      flex-direction: column;
    }

    &__single {
      width: 25%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      background-color: $blue-secondary;
      padding: 1rem .5rem;
      position: relative;

      &:last-of-type {
        @media screen and (max-width: 900px) {
          border-bottom: 2px solid $blue-secondary;
        }
      }

      @media screen and (max-width: 900px) {
        width: 100%;
        justify-content: flex-start;
        padding: 1rem 2rem;
        border-bottom: 2px solid darken($blue-secondary, 10%);
        display: none;
      }
      @media screen and (max-width: 550px) {
        padding: 1.5rem 2rem;
      }

      img {
        max-width: 1.8rem;
        margin-right: 1rem;
        display: none;
      }
      span {
        color: white;
        font-size: 1.7rem;
        font-weight: 600;
      }
      .desktop {
        @media screen and (max-width: 900px) {
          display: none;
        }
      }
      .mobile {
        display: none;
        @media screen and (max-width: 900px) {
          display: inline-block;
          font-size: 1.4rem;
        }
      }
      button {
        background-color: $blue-secondary;
        color: white;
        border-radius: 1rem;
        padding: .5rem 1rem;
        font-family: 'Roboto', serif;
        font-weight: 600;
        border: none;
        font-size: 1.3rem;
        position: absolute;
        bottom: -2rem;
        left: 0;
        right: 0;
        margin: auto;
        display: none;

        &:hover {
          background-color: darken($blue-secondary, 5%);
        }

        @media screen and (max-width: 900px) {
          bottom: auto;
          top: auto;
          margin: auto;
          right: 1rem;
          left: initial;
        }
      }
    }
    .isActive {
      background-color: $blue-primary;

      @media screen and (max-width: 900px) {
        border-bottom: 2px solid $blue-primary;
        display: flex;
      }
    }
    .isComplete {
      background-color: lighten($blue-primary, 5%);
      @media screen and (max-width: 900px) {
        background-color: $blue-primary;
        display: flex;
      }
      img, button {
        display: flex;
      }
    }
  }

  &__body {
    background-color: white;
    overflow: hidden;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    padding: 5rem 2rem;
    position: relative;

    @media screen and (max-width: 550px) {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      padding: 5rem 2.5rem;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;

    &__single {
      border: 2px solid $blue-primary;
      color: $blue-primary;
      padding: .5rem 1rem;
      font-size: 1.6rem;
      font-weight: 600;
      border-radius: 1rem;
      background-color: transparent;
      margin-right: 1rem;
      white-space: nowrap;

      &:hover {
        background-color: darken(white, 5%);
      }

      small {
        font-size: 1.3rem;
      }
    }
    .isActive {
      background-color: $blue-secondary;
      border: 2px solid $blue-secondary;
      color: white;
      pointer-events: none;
      small {
        color: white;
      }
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $blue-primary;
    text-align: center;

    a {
      color: $blue-primary;
      font-size: 1.8rem;
      font-weight: 600;
    }

    h1 {
      font-size: 3.5rem;
      margin: 0 0 1.5rem;
    }

    h2,h3,h4,h5 {
      font-size: 2.5rem;
      margin: 0 0 .5rem;
    }
    p {
      font-size: 1.8rem;
      color: $blue-primary;
    }
    &--left {
      align-items: flex-start;
    }
    &--border {
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid $gray-primary;
    }
  }

  &__info-btn {
    @include square-dimensions(2.25rem);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;

    &--blue {
      background-color: $blue-primary;
      span {
        color: white;
        font-weight: 700;
        font-family: serif;
        font-size: 1.6rem;
      }
    }
    &--white {
      background-color: white;
      span {
        color: $blue-primary;
        font-weight: 700;
        font-family: serif;
        font-size: 1.6rem;
      }
    }
  }

  &__next {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 3rem 0;
    button {
      background-color: $blue-primary;
      color: white;
      border: none;
      text-align: center;
      display: flex;
      align-items: center;
      font-size: 2.3rem;
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      border-radius: 1rem;
      padding: .75rem 2rem;

      &:hover {
        background-color: darken($blue-primary, 5%);
      }
    }
  }

  &__totals {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem 2rem;
    background-color: $blue-secondary;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;

    @media screen and (max-width: 550px) {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    span {
      font-size: 1.8rem;
      color: white;
      font-weight: 600;
    }
  }

  &__select {
    margin-right: 1rem;
    position: relative;
    width: 25rem;

    &:nth-of-type(1), &:nth-of-type(3) {
      width: 20rem;
    }

    &:last-of-type {
      margin-right: 0;
    }
    select {
      padding: .25rem 2rem .25rem 1rem;
      font-size: 1.8rem;
      border: 1px solid $blue-primary;
      -webkit-appearance: none;
      border-radius: .5rem;
      width: 100%;
      color: $blue-primary;
    }
    img {
      max-width: 1.4rem;
      position: absolute;
      top: 0;
      right: 1.25rem;
      pointer-events: none;
      bottom: 0;
      margin: auto;
    }
  }

  &__dob {
    width: 70rem;
    margin: 0 auto;

    @media screen and (max-width: 800px) {
      width: 100%
    }

    &__input {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;

      label {
        font-size: 2rem;
        font-family: 'Roboto', serif;
        margin-bottom: .25rem;
        display: flex;
        align-items: center;

        .c-subscription__info-btn {
          margin-left: .5rem;
        }
      }

      &__wrapper {
        display: flex;

        @media screen and (max-width: 800px) {
          .c-subscription__select {
            width: 33.333%;
            margin-right: 0;

            &:nth-of-type(2) {
              margin: 0 1rem;
            }
          }
        }
      }
    }
    &__result {
      display: flex;
      align-items: center;
      padding: 1rem 0;
      border-top: 1px solid darken($gray-primary, 5%);
      &__single {
        border: 1px solid $blue-primary;
        padding: .5rem 1rem;
        border-radius: .5rem;
        margin-right: 1rem;
        text-align: center;
      }
    }
  }

  &__location {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 70rem;
    width: 100%;
    margin: 0 auto;

    &__options {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 2rem;
      width: 100%;

      &__single {
        border: 2px solid $blue-primary;
        border-radius: 1rem;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 2rem;
        background-color: white;

        &:first-of-type {
          margin-bottom: 1.5rem;
        }

        figure {
          @include square-dimensions(3rem);
          border-radius: 50%;
          border: 2px solid $blue-primary;
          margin-right: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            opacity: 0;
          }
        }
        span {
          text-align: left;
          font-size: 1.8rem;
          font-weight: 600;
        }
      }
      .isActive {
        background-color: $blue-secondary;
        border: 2px solid $blue-secondary;
        figure {
          border: 2px solid white;
          svg {
            opacity: 1;

          }
        }
        span {
          color: white;
        }
      }
    }
  }

  &__options {
    &__header {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 2rem;

      @media screen and (max-width: 500px) {
        flex-direction: column;
      }

      .info-btn {
        display: flex;
        align-items: center;
        text-decoration: underline;
        background-color: transparent;
        border: none;

        @media screen and (max-width: 500px) {
          margin-bottom: .5rem;
        }

        div {
          margin-right: .5rem;

        }
      }
      hr {
        height: 3rem;
        width: 2px;
        background-color: $gray-primary;
        margin: 0 2rem;
        display: flex;

        @media screen and (max-width: 500px) {
          display: none;
        }
      }
      &__location {
        display: flex;
        align-items: center;

        span {
          font-weight: 600;
        }

        button {
          text-decoration: underline;
          background-color: transparent;
          border: none;
        }
      }
    }
    &__buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1rem 0 2rem;

      @media screen and (max-width: 500px) {
        flex-direction: column;
      }

      button {
        margin: 0 1rem;
        padding: 1rem 2rem;
        background-color: $blue-primary;
        border: none;
        border-radius: 1rem;

        &:hover {
          background-color: darken($blue-primary, 5%);
        }

        @media screen and (max-width: 500px) {
          width: 100%;
          margin: .5rem 0;
        }

        span {
          color: white;
          font-weight: 600;
          font-size: 1.8rem;
        }
        &:nth-of-type(2) {
          background-color: $blue-secondary;

          &:hover {
            background-color: darken($blue-secondary, 5%);
          }
        }
      }
    }
    &__single {
      margin-bottom: 2rem;

      &:last-of-type {
        margin-bottom: 0;
      }
      &--dark {
        header {
          background-color: $blue-primary;
        }
      }
      &--light {
        header {
          background-color: $blue-secondary;

          div {
            div {
              background-color: $blue-primary !important;
            }
          }
        }
      }

      header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: .5rem 1rem;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;

        @media screen and (max-width: 500px) {
          flex-direction: column;
          align-items: flex-start;
        }

        div {
          display: flex;
          align-items: center;
          color: white;
          font-weight: 600;

          @media screen and (max-width: 500px) {
            justify-content: space-between;
            width: 100%;
          }

          span {
            color: white;
            font-size: 1.8rem;
          }
          div {
            background-color: $blue-secondary;
            font-size: 1.4rem;
            border-radius: 1rem;
            padding: .25rem .5rem;
            margin-left: .5rem;
            width: auto;
          }
        }
        button {
          background-color: transparent;
          border: none;
          color: white;
          text-decoration: underline;
          font-weight: 600;
          font-size: 1.7rem;

          @media screen and (max-width: 500px) {
            text-align: right;
            padding-left: 0;
          }
        }
      }

      &__body {
        padding: 2rem;
        background-color: $gray-primary;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        &:last-of-type {
          padding-bottom: 1rem;
        }
        h3 {
          font-size: 2rem;
          margin-bottom: 2rem;
        }
        &__row {
          display: flex;
          margin: 0 0 1rem;
          border-bottom: 1px solid darken($gray-primary, 10%);
          padding: 0 0 1rem;

          &:last-of-type {
            margin-bottom: 0;
            border-bottom: none;
          }
          span {
            min-width: 12.5rem;
            width: 12.5rem;
            display: flex;
            font-size: 1.7rem;
            font-weight: 600;

            @media screen and (max-width: 500px) {
              min-width: 10rem;
              width: 10rem;
            }
          }
          div {
            display: flex;
            flex-wrap: wrap;
            margin-top: -.5rem;

            button {
              background-color: white;
              border-radius: .5rem;
              border: 1px solid $blue-primary;
              font-size: 1.6rem;
              margin: .5rem;
              white-space: nowrap;

              &:hover {
                background-color: darken(white, 5%);
              }
            }
          }

          &__chosen {

            @media screen and (max-width: 500px) {
              div {
                flex-direction: column;
              }
            }
            button {
              background-color: $blue-primary !important;
              color: white;
              font-weight: 600;
              pointer-events: none;

              @media screen and (max-width: 500px) {
                margin-left: 0 !important;
              }
            }
            .changeDate {
              background-color: transparent !important;
              border: none;
              pointer-events: all;
              text-decoration: underline;
              padding: 0;
              margin-left: 2rem;
              color: $blue-primary;
              font-weight: 600;

              &:hover {
                color: darken($blue-secondary, 1%);
              }

              @media screen and (max-width: 500px) {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }

  &__extra {
    max-width: 70rem;
    margin: 0 auto;
    &__single {
      display: flex;
      flex-direction: column;
      margin-bottom: 1.5rem;
      padding-bottom: 1.5rem;
      border-bottom: 1px solid $gray-primary;

      &:last-of-type {
        border-bottom: none;
      }

      &__text {
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 1.3;
        margin-bottom: 1rem;
        color: $blue-primary;
      }
    }
  }

  &__info {
    max-width: 70rem;
    margin: 0 auto;
  }

  &__message {
    display: flex;
    align-items: center;
    border-radius: 1rem;
    padding: 1rem;
    margin-bottom: 1.5rem;

    .c-subscription__info-btn {
      margin-right: 1rem;
    }

    p {
      font-size: 1.7rem;
      font-weight: 600;
    }
    &--red {
      background-color: $red-primary;

      p {
        color: white;
      }
    }
  }
}
