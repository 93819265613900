/*------------------------------------*\
  #MEDIA QUERRIES
\*------------------------------------*/

@mixin mq-mobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin mq-mobile-up {
  @media (min-width: #{$mobile-width}) {
    @content;
  }
}

@mixin mq-tablet {
  @media (max-width: #{$tablet-width}){
    @content;
  }
}

@mixin mq-between-tablet {
  @media (max-width: #{$between-tablet}){
    @content;
  }
}

@mixin mq-tablet-up {
  @media (min-width: #{$tablet-width}){
    @content;
  }
}

@mixin mq-desktop {
  @media (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin mq-desktop-up {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}


// Boostrap/Foundation
@mixin mq-xl {
  @media (min-width: #{$xl}) {
    @content;
  }
}

@mixin mq-lg {
  @media (min-width: #{$lg}) {
    @content;
  }
}

@mixin mq-md {
  @media (min-width: #{$md}) {
    @content;
  }
}

@mixin mq-sm {
  @media (min-width: #{$sm}) {
    @content;
  }
}

@mixin mq-xs {
  @media (min-width: #{$xs}) {
    @content;
  }
}

@mixin square-dimensions($size) {
  width: $size;
  height: $size;
  min-width: $size;
  min-height: $size;
}
