@import '0.defaults/defaults';
@import '1.objects/objects';
@import '2.components/components';
@import '3.utilities/utilities';
@import '4.themes/themes';
@import '5.scope/scope';
@import '6.is-has/is-has';
@import '7.hacks/hacks';


.btn-is-disabled{
  opacity: .2;
  pointer-events: none;
  
}
