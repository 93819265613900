.c-breadcrumbs {
  ul {
    display: flex;
    li {
      a {
        font-size: 2rem;
        text-decoration: none;
        text-transform: lowercase;

        &:hover {
          text-decoration: underline;
        }
      }
      &:last-of-type {
        a {
          pointer-events: none;
        }
        i {
          display: none;
        }
      }
      i {
        margin: 0 1.2rem;
        font-size: 1rem;
      }
    }
  }
}
