.u-bg-cover-center {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.u-bg-contain-top {
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
}
