/*------------------------------------*\
  #GLOBAL STYLES
\*------------------------------------*/

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html {
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media screen and (min-width: 2500px) {
    font-size: 70%;
  }
  @media screen and (min-width: 2100px) {
    font-size: 67.5%;
  }
  @media screen and (max-width: 1550px) {
    font-size: 50%;
  }
  @media screen and (max-width: 1350px) {
    font-size: 43%;
  }
  @media screen and (max-width: 1023px) {
    font-size: 57%;
  }
  @include mq-tablet {
    font-size: 54%;
  }
  @include mq-mobile {
    font-size: 48%;
  }
}

body {
  overflow-x:hidden;
}

select::-ms-expand {
  display: none;
}

// img reset
img {
  max-width: 100%;
  height: auto;
}

// basic hr
hr,
.hr {
  display: block;
  height: 1px;
  border: 0;
  margin: 0;
  padding: 0;
}

figure {
  margin: 0;
}
