.c-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  opacity: 0;
  pointer-events: none;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #293658;
    opacity: .8;
  }

  &__content {
    background-color: $clr-secondary;
    position: relative;
    z-index: 5;
    min-width: 75rem;
    width: 75rem;
    border-radius: 1rem;
    overflow: hidden;

    @media screen and (max-width: 800px) {
      min-width: calc(100% - 10rem);
      width: calc(100% - 10rem);
    }
    @media screen and (max-width: 550px) {
      min-width: calc(100% - 6rem);
      width: calc(100% - 6rem);
    }
  }

  &__header {
    padding: 1.5rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $blue-secondary;

    button {
      background: none;
      border: none;
    }

    h4,h3,h2,h5, button {
      color: white;
      margin: 0;
    }
  }

  &__body {
    padding: 2rem 3rem;
    overflow-x: auto;

    &__subject {
      width: 100%;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid $gray-primary;
    }

    p {
      font-size: 1.8rem;
      margin-bottom: 2rem;
    }
    table {
      margin-bottom: 3rem;
      min-width: 450px;
      tr,td,th {
        font-size: 1.6rem;
      }
      th {
        font-weight: 600;
      }
    }
  }

  &__close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: transparent;
    @include square-dimensions(5rem);
    padding: 0;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.js-modalActive {
  opacity: 1;
  pointer-events: all;
}
