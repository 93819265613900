.c-search-results {
  &__intro {
    padding: 5rem 0;

    @media screen and (max-width: 500px) {
      padding: 4rem 0;
    }

    .o-container {
      display: flex;
      flex-direction: column;
    }
    h1 {
      margin: 0;
      font-family: 'Merlod-Regular', serif;
      font-weight: 300;

      strong {
        font-size: 600;
      }
    }
    p {
      margin: 0;
    }
  }
  &__wrap {
    padding: 5rem 0;

    @media screen and (max-width: 500px) {
      padding: 3rem 0;
    }

    .o-container {

    }
  }
  &__single {
    background-color: $clr-secondary;
    border: 2px solid $clr-primary;
    padding: 4rem;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 2rem;

    @media screen and (max-width: 700px) {
      padding: 3rem;
    }

    @media screen and (max-width: 450px) {
      flex-direction: column;
      align-items: flex-start;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &__image {
      display: flex;
      margin-right: 5rem;

      @media screen and (max-width: 700px) {
        margin-right: 2rem;
      }

      @media screen and (max-width: 450px) {
        margin-right: 0;
        margin-bottom: 2rem;
      }

      img {
        max-width: 15rem;
        max-height: 15rem;

        @media screen and (max-width: 700px) {
          max-width: 10rem;
          max-height: 10rem;
        }
        @media screen and (max-width: 550px) {
          max-width: 8rem;
          max-height: 8rem;
        }
      }
    }
    &__text {
      display: flex;
      width: 100%;
      a {
        text-decoration: none;
        display: flex;
        flex-direction: column;
        width: calc(100% - 7.5rem);
        h2 {
          margin: 0;
          font-size: 3.5rem;

          @media screen and (max-width: 700px) {
            font-size: 3rem;
          }
          @media screen and (max-width: 550px) {
            font-size: 2.5rem;
          }

          &:hover {
            text-decoration: underline;
          }
        }
        p {
          margin: 1rem 0 0;

          @media screen and (max-width: 700px) {
            display: none;
          }
        }
      }
    }
    i {
      position: absolute;
      right: 3rem;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 5rem;
      height: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 700px) {
        right: 2rem;
      }
    }
  }
}
