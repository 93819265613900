/*------------------------------------*\
  #Form
\*------------------------------------*/

table{
  width: 100%;
}

thead{
  // border-bottom: 2px solid rgba($color-border, 0.3);
}

th{
  text-align: left;
  font-size: 1.7rem;
}

tr {
  // border-bottom: 1px solid rgba($color-border, 0.2);

  &:last-child{
    border-bottom: none;
  }
}

tr:after {
  margin-bottom: 30px;
  content: '';
  display: inline-block;

  &:last-child{
    display: none;
  }
}
