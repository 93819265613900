/*------------------------------------*\
  #TYPOGHRAPY
\*------------------------------------*/

body {
  font-weight: 400;
  line-height: 1.6;
  font-size: 2rem;
  font-family: 'Merlod-Regular', serif;
  color: $clr-primary;
}

h1,
h2,
h3,
h4,
h5,
h6,
th {
  margin: 0 0 0.6em 0;
  //font-family: 'Merlod-SemiBold', serif;
  line-height: 1;
}

h1,
.h1 {
  font-size: 5.5rem;

  @media (max-width: 550px) {
    font-size: 4rem;
  }
}

h2,
.h2 {
  font-size: 4.5rem;

  @media (max-width: 550px) {
    font-size: 3.2rem;
  }
}

h3,
.h3 {
  font-size: 3.5rem;

  @media (max-width: 550px) {
    font-size: 2rem;
  }
}

h4,
.h4 {
  font-size: 2.5rem;

  @media (max-width: 550px) {
    font-size: 1.7rem;
  }
}

h5,
.h5 {
  font-size: 2rem;

  @media (max-width: 550px) {
    font-size: 1.5rem;
  }
}

h6,
.h6 {
  font-size: 1.8rem;

  @media (max-width: 550px) {
    font-size: 1.4rem;
  }
}

p,
.p {
  margin: 0 0 1em 0;
  //font-family: 'Merlod-Light', serif;
  font-size: 2.4rem;
  line-height: 1.2;
  color: $clr-primary;

  &:last-child {
    margin-bottom: 0;
  }

  strong {
    //font-family: 'Merlod-SemiBold', serif;
  }
}

a {
  //font-family: 'Merlod-SemiBold', serif;
  color: $clr-primary;
}

small {
  font-size: 1.8rem;
}
